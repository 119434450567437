// Global variables to track test completion status
let webcamTested = false;
let micTested = false;
let speakerTested = false;

// Function to set up microphone test
function setupMicTest() {
  const micButton = document.getElementById('mic-test');
  const micContainer = document.getElementById('mic-container');
  const audioVisualizer = document.getElementById('audio-visualizer');

  // Check if all required elements are present
  if (!micButton || !micContainer || !audioVisualizer) {
    console.error('One or more elements for mic test not found');
    return;
  }

  const ctx = audioVisualizer.getContext('2d');

  micButton.addEventListener('click', async () => {
    try {
      // Request access to the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Set up audio context and analyser
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      microphone.connect(analyser);
      
      // Configure analyser
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Function to draw the audio visualizer
      function draw() {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);
        
        // Clear the canvas
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.fillRect(0, 0, audioVisualizer.width, audioVisualizer.height);
        
        // Draw the frequency bars
        const barWidth = (audioVisualizer.width / bufferLength) * 2.5;
        let x = 0;
        for (let i = 0; i < bufferLength; i++) {
          const barHeight = dataArray[i] / 2;
          ctx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
          ctx.fillRect(x, audioVisualizer.height - barHeight, barWidth, barHeight);
          x += barWidth + 1;
        }
      }

      // Start drawing the visualizer
      draw();
      
      // Show the microphone container and mark test as completed
      micContainer.style.display = 'block';
      micTested = true;
      checkAllTestsCompleted();
    } catch (err) {
      console.error('Error accessing microphone:', err);
      alert('Unable to access microphone. Please check your permissions.');
    }
  });
}

// Function to set up speaker test
function setupSpeakerTest() {
  const speakerButton = document.getElementById('speaker-test');
  const audio = new Audio('https://customerinterviewsai-public.s3.eu-west-2.amazonaws.com/tell-me-a-joke.mp3');

  speakerButton.addEventListener('click', () => {
    audio.play()
      .then(() => {
        speakerTested = true;
        checkAllTestsCompleted();
      })
      .catch(err => {
        console.error('Error playing audio:', err);
        alert('Unable to play audio. Please check your audio settings.');
      });
  });
}

// Function to set up webcam test
function setupWebcamTest() {
  const webcamButton = document.getElementById('webcam-test');
  const webcamContainer = document.getElementById('webcam-container');
  const webcamVideo = document.getElementById('webcam-video');

  if (webcamButton) {
    webcamButton.addEventListener('click', async () => {
      try {
        // Request access to the webcam
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        
        // Set up video stream and display
        webcamVideo.srcObject = stream;
        webcamVideo.style.transform = 'scaleX(-1)'; // Mirror mode
        webcamContainer.style.display = 'block';
        
        // Mark test as completed
        webcamTested = true;
        checkAllTestsCompleted();
      } catch (err) {
        console.error('Error accessing webcam:', err);
        alert('Unable to access webcam. Please check your permissions.');
      }
    });
  } else {
    console.log('Webcam test button not found');
  }
}

// Function to stop preview of webcam and microphone
function stopPreview() {
  // Stop webcam preview
  const webcamVideo = document.getElementById('webcam-video');
  if (webcamVideo.srcObject) {
    webcamVideo.srcObject.getTracks().forEach(track => track.stop());
  }
  document.getElementById('webcam-container').style.display = 'none';

  // Stop audio visualizer and microphone
  const audioVisualizer = document.getElementById('audio-visualizer');
  if (audioVisualizer) {
    const ctx = audioVisualizer.getContext('2d');
    ctx.clearRect(0, 0, audioVisualizer.width, audioVisualizer.height);
  }
  document.getElementById('mic-container').style.display = 'none';

  // Stop any ongoing audio context and microphone stream
  if (window.audioContext) {
    window.audioContext.close().then(() => {
      console.log('AudioContext closed');
      window.audioContext = null;
    });
  }

  // Stop microphone stream
  if (window.microphoneStream) {
    window.microphoneStream.getTracks().forEach(track => track.stop());
    window.microphoneStream = null;
    console.log('Microphone stream stopped');
  }
}

// Function to check if all tests are completed
function checkAllTestsCompleted() {
  if (webcamTested && micTested && speakerTested) {
    const startButton = document.getElementById('start-call');
    if (startButton) {
      startButton.style.display = '';
      console.log("All tests completed, showing start button");
      
      // Add an event listener to stop the preview webcam when the start button is clicked
      startButton.addEventListener('click', stopPreview, { once: true });
    } else {
      console.error("Start button not found");
    }
  } else {
    console.log("Tests not all completed yet:", { webcamTested, micTested, speakerTested });
  }
}

// Function to initialize the interview setup
export function initializeInterviewSetup() {
  const testButtons = document.getElementById('test-buttons');
  if (testButtons) {
    // Set up individual tests if their respective buttons exist
    if (document.getElementById('webcam-test')) {
      setupWebcamTest();
    }
    if (document.getElementById('mic-test')) {
      setupMicTest();
    }
    if (document.getElementById('speaker-test')) {
      setupSpeakerTest();
    }
    
    // Handle start button visibility
    const startButton = document.getElementById('start-call');
    if (startButton) {
      startButton.style.display = 'none';
    }
  } else {
    // If test buttons don't exist, show the start button immediately
    const startButton = document.getElementById('start-call');
    if (startButton) {
      startButton.style.display = '';
    }
  }
  
  // Hide stop button initially
  const stopButton = document.getElementById('stop-call');
  if (stopButton) {
    stopButton.style.display = 'none';
  }
}