import Cookies from "js-cookie";

// Rails doesn't support every timezone that Intl supports
export function findTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timeZone);
  return timeZone;
}

document.addEventListener("turbo:load", () => {
  Cookies.set("browser_time_zone", findTimeZone());
  // console.log(Cookies.get("browser_time_zone")); // Add this line
});
