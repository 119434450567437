import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input", "clearButton", "shortcutHint"]

  connect() {
    this.toggleClearButton()
    this.addKeyboardShortcuts()
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
    this.toggleClearButton()
  }

  clear() {
    this.inputTarget.value = ""
    this.formTarget.requestSubmit()
    this.toggleClearButton()
  }

  toggleClearButton() {
    if (this.inputTarget.value === "") {
      this.clearButtonTarget.classList.add("hidden")
    } else {
      this.clearButtonTarget.classList.remove("hidden")
    }
  }

  hideShortcutHint() {
    this.shortcutHintTarget.classList.add("hidden")
  }

  showShortcutHint() {
    if (this.inputTarget.value === "") {
      this.shortcutHintTarget.classList.remove("hidden")
    }
  }

  addKeyboardShortcuts() {
    document.addEventListener('keydown', this.handleKeydown.bind(this))
  }

  handleKeydown(event) {
    if (event.key === 's' && !this.isInputActive()) {
      event.preventDefault()
      this.inputTarget.focus()
    } else if (event.key === 'Escape' && this.isSearchInputActive()) {
      event.preventDefault()
      this.inputTarget.blur()
      this.showShortcutHint()
    }
  }

  isInputActive() {
    return document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA'
  }

  isSearchInputActive() {
    return document.activeElement === this.inputTarget
  }
}
