import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.handleKeyPress = this.handleKeyPress.bind(this)
    document.addEventListener("keydown", this.handleKeyPress)
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyPress)
  }

  handleKeyPress(event) {
    if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
      return
    }

    const key = event.key
    if (["1", "2", "3", "4"].includes(key)) {
      const link = document.querySelector(`[data-hotkey="${key}"]`)
      if (link) {
        event.preventDefault()
        link.click()
      }
    }
  }
}
