import Vapi from "@vapi-ai/web";

// Initialize Vapi with your public key
const vapi = new Vapi("644cc633-ac25-4532-9009-23a26662aeee");

// Set up event listeners for various Vapi events

// Event: Call Start
vapi.on("call-start", () => {
  console.log("Call has started.");
});

// Event: Speech Start
vapi.on("speech-start", () => {
  console.log("Assistant speech has started.");
});

// Event: Message Received
vapi.on("message", (message) => {
  console.log(message);
});

// Event: Speech End
vapi.on("speech-end", () => {
  console.log("Assistant speech has ended.");
});

// Event: Status Update
vapi.on("status-update", (statusUpdate) => {
  console.log("Status update:", statusUpdate);
  if (statusUpdate.status === 'in-progress' && !interviewStarted) {
    interviewStarted = true;
    handleInterviewStart();
  } else if (statusUpdate.status === 'ended') {
    handleInterviewEnd();
  }
});

// Utility Functions

// Function to stop preview of webcam and microphone
function stopPreview() {
  console.log("Stopping preview");
  
  // Stop webcam preview
  const webcamVideo = document.getElementById('webcam-video');
  if (webcamVideo && webcamVideo.srcObject) {
    webcamVideo.srcObject.getTracks().forEach(track => track.stop());
    webcamVideo.srcObject = null;
  }
  const webcamContainer = document.getElementById('webcam-container');
  if (webcamContainer) {
    webcamContainer.style.display = 'none';
  }

  // Stop microphone preview
  if (window.audioContext) {
    window.audioContext.close();
    window.audioContext = null;
  }
  if (window.audioStream) {
    window.audioStream.getTracks().forEach(track => track.stop());
    window.audioStream = null;
  }
  const testButtons = document.getElementById('test-buttons');
  if (testButtons) {
    testButtons.style.display = 'none';
  }

  // Reset any UI elements related to the preview
  const micStatus = document.getElementById('mic-status');
  if (micStatus) {
    micStatus.textContent = 'Please talk';
  }
}

// Function to start a call
function startCall(firstName, studySlug, interviewSlug) {
  console.log("startCall called with:", { firstName, studySlug, interviewSlug });

  // Only stop preview for interview calls
  if (interviewSlug) {
    stopPreview();
  }

  // Construct the server URL
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : "";
  const baseUrl = `${protocol}//${hostname}${port}`;
  const serverUrl = `${baseUrl}/vapi/webhook`;

  // Get the interview prompt from a data attribute
  const interviewPromptElement = document.querySelector('[data-interview-prompt]');
  const interviewPrompt = interviewPromptElement ? interviewPromptElement.dataset.interviewPrompt : '';

  // Define assistant options
  const assistantOptions = {
    name: "AI Interview Assistant",
    firstMessage: firstName ? `Hello ${firstName}, thank you for participating in this interview. How are you doing today?` : "Hello, how can I assist you today?",
    transcriber: {
      provider: "deepgram",
      model: "nova-2",
      language: "en-US",
    },
    voice: {
      provider: "playht",
      voiceId: "jennifer",
    },
    model: {
      provider: "openai",
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: interviewPrompt || `You are an AI assistant conducting an interview. Please follow the interview guide and ask questions accordingly. The interviewee's first name is ${firstName}.`
        },
      ],
    },
  };

  // Define assistant IDs
  const studyAssistantId = "9b16eb03-890e-4796-85e5-2521041d9e99";
  const interviewAssistantId = assistantOptions;

  // Choose the appropriate assistant ID
  let assistantId = studySlug && interviewSlug ? interviewAssistantId : studyAssistantId;

  // Validate input
  if (!studySlug) {
    console.error("Invalid combination of studySlug and interviewSlug provided.");
    return;
  }

  // Define assistant overrides
  const assistantOverrides = {
    variableValues: {
      first_name: firstName,
      study_id: studySlug,
      interview_id: interviewSlug,
    },
    serverUrl: serverUrl,
    artifactPlan: {
      videoRecordingEnabled: true,
      recordingS3PathPrefix: `interviews/${studySlug}/` // Adjust this path as needed
    }
  };

  // Start the Vapi call
  vapi
    .start(assistantId, assistantOverrides)
    .then(() => {
      console.log("Vapi call initiated");
      console.log("Study ID:", studySlug);
      console.log("Interview ID:", interviewSlug);
      console.log("Server URL:", serverUrl);
      handleInterviewStart();
      startWebcamDisplay(); // Add this line to start displaying the webcam
    })
    .catch((error) => {
      console.error("Error starting call:", error);
    });
}

// Add a new function to start displaying the webcam
function startWebcamDisplay() {
  navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
      const webcamVideo = document.getElementById('webcam-video');
      if (webcamVideo) {
        webcamVideo.srcObject = stream;
        webcamVideo.style.transform = 'scaleX(-1)'; // Mirror mode
        document.getElementById('webcam-container').style.display = 'block';
      }
    })
    .catch(error => {
      console.error("Error accessing webcam:", error);
    });
}

// Modify the stopCall function
function stopCall() {
  try {
    vapi.stop();
    console.log("Call stopped");
    
    // Stop all media tracks (both video and audio)
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(error => {
        console.error("Error accessing media devices:", error);
      });

    // Stop any existing media streams
    const webcamVideo = document.getElementById('webcam-video');
    if (webcamVideo && webcamVideo.srcObject) {
      webcamVideo.srcObject.getTracks().forEach(track => track.stop());
      webcamVideo.srcObject = null;
    }

    // Hide webcam container
    const webcamContainer = document.getElementById('webcam-container');
    if (webcamContainer) {
      webcamContainer.style.display = 'none';
    }

    // Stop any existing audio context
    if (window.audioContext) {
      window.audioContext.close();
      window.audioContext = null;
    }

  } catch (error) {
    console.error("Error stopping call:", error);
  }
}

// Update the stopWebcamDisplay function
function stopWebcamDisplay() {
  const webcamVideo = document.getElementById('webcam-video');
  const webcamContainer = document.getElementById('webcam-container');
  
  if (webcamVideo && webcamVideo.srcObject) {
    webcamVideo.srcObject.getTracks().forEach(track => track.stop());
    webcamVideo.srcObject = null;
  }
  
  if (webcamContainer) {
    webcamContainer.style.display = 'none';
  }
}

// Function to handle interview start
function handleInterviewStart() {
  console.log("Interview has started.");
  document.getElementById("start-call").style.display = "none";
  document.getElementById("stop-call").style.display = "";
}

// Function to handle interview end
function handleInterviewEnd() {
  console.log("Interview has ended.");
  document.getElementById("start-call").style.display = "";
  document.getElementById("stop-call").style.display = "none";
  interviewStarted = false;
}

// Global variables
let interviewStarted = false;



// Export functions for use in other parts of the application
export { startCall, stopCall, stopPreview, startWebcamDisplay, stopWebcamDisplay };
