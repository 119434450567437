/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

require("@rails/activestorage").start();
import "@rails/actiontext";

// ActionCable Channels
import "./channels";

// Stimulus controllers
import "./controllers";

// Jumpstart Pro & other Functionality
import "./src/**/*";
require("local-time").start();

require("jquery");
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
require("@nathanvda/cocoon");

// Import Vapi setup
import { startCall, stopCall, stopPreview, startWebcamDisplay, stopWebcamDisplay } from "./vapiSetup.js";

// Import interview setup
import { initializeInterviewSetup } from "./interviewSetup";

// Stimulus application setup
import { Application } from "@hotwired/stimulus"
import SearchController from "./controllers/search_controller"

const application = Application.start()
application.register("search", SearchController)

// Function to add event listeners to the buttons
function addEventListeners() {
  const startButton = document.getElementById("start-call");
  const stopButton = document.getElementById("stop-call");

  if (startButton) {
    const firstName = startButton.dataset.firstName;
    const studySlug = startButton.dataset.studySlug;
    const interviewSlug = startButton.dataset.interviewSlug;

    startButton.addEventListener("click", () => {
      startCall(firstName, studySlug, interviewSlug);
      startButton.style.display = "none";
      if (stopButton) {
        stopButton.style.display = ""; // Show the stop button
      }
    });
  }

  // Only set up stop button listener if we're on an interview page
  if (stopButton && document.querySelector('[data-interview-id]')) {
    stopButton.addEventListener("click", () => {
      stopCall();
      // Show startButton and hide stopButton when call stops
      if (startButton) startButton.style.display = "";
      stopButton.style.display = "none";
    });
  }

  // Remove the webcamTestButton event listener from here, as it's handled in interviewSetup.js

  // Add event listener for ESC and Backspace keys
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      event.preventDefault();
      const backButton = document.getElementById("back-button");
      if (backButton) {
        backButton.click();
      }
    }
  });

  const flashElement = document.getElementById("flash");
  const closeIcon = document.getElementById("close-flash"); // Select the SVG icon by its ID

  if (flashElement) {
    // Hide the flash message after 3 seconds
    setTimeout(() => {
      flashElement.classList.add("flash-slide-out");
    }, 3000); // 3000 milliseconds = 3 seconds

    // Animate the flash message down off the screen when the "X" icon is clicked
    if (closeIcon) {
      closeIcon.addEventListener("click", () => {
        flashElement.classList.add("flash-slide-out");
      });
    }
  }

  // Initialize interview setup only if test buttons exist
  const testButtons = document.getElementById('test-buttons');
  if (testButtons) {
    initializeInterviewSetup();
  }
}


document.addEventListener("turbo:load", function() {
  const testInterviewBtn = document.getElementById('run-test-interview-btn');
  if (testInterviewBtn) {
    testInterviewBtn.addEventListener('click', function(event) {
      event.preventDefault();
      const originalText = this.innerText;
      this.innerText = "Generating test AI interview..";
      this.disabled = true;

      setTimeout(() => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.form.action;
        
        // Copy any hidden inputs from the original form
        this.form.querySelectorAll('input[type="hidden"]').forEach(input => {
          form.appendChild(input.cloneNode());
        });

        document.body.appendChild(form);
        form.submit();
      }, 5000);
    });
  }
});

// Add event listeners when Turbo loads a page
document.addEventListener("turbo:load", addEventListeners);

// Start Rails UJS
Rails.start();

