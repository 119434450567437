import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submitForm(event) {
    const form = event.target.closest("form");
    const isPersisted = form.dataset.persisted === "true";
    if (isPersisted) {
      form.requestSubmit();
    }
  }
}
